<template>
  <div class="Trace document-content">
    <div class="docHeader">
      <h2>溯源中心</h2>
      <div class="updateTime">发布时间：2022-01-21 9:00</div>
    </div>
    <Des class="markdown-body"></Des>
  </div>
</template>

<script setup>
import Des from "./mdDocument/溯源中心接口.md";
export default {
  data() {
    return {};
  },
  components: {
    Des,
  },
};
</script>

<style lang="less" scoped>
.Trace {
}
</style>